import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { baseurl } from '../../url';
import Navbar from './FacultyNavbar';
import './att.css';

const AttendanceForm = () => {
  const [semester, setSemester] = useState('');
  const [course, setCourse] = useState('');
  const [subject, setSubject] = useState('');
  const [selectedHours, setSelectedHours] = useState([]); // Changed to an array for multiple hours
  const [date, setDate] = useState('');
  const [division, setDivision] = useState('');
  const [students, setStudents] = useState([]);
  const [courses, setCourses] = useState([]);
  const [semesters, setSemesters] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [teachername, setTeacherName] = useState('');
  const [loading, setLoading] = useState(false);
  const [markAllPresent, setMarkAllPresent] = useState(false);
  const [alreadyMarked, setAlreadyMarked] = useState(false);
  const [existingAttendance, setExistingAttendance] = useState([]);
  const [noStudentsMessage, setNoStudentsMessage] = useState(false);
  const [lab, setLab] = useState('');

  useEffect(() => {
    const fetchCoursesAndSemesters = async () => {
      const email = localStorage.getItem('email');
      try {
        const response = await axios.post(`${baseurl}/api/data/attendance`, { email });
        const { subjects, semesters, branches, teachername } = response.data;
        setCourses(branches || []);
        setSemesters(semesters || []);
        setSubjects(subjects || []);
        setTeacherName(teachername);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchCoursesAndSemesters();

    const currentDate = new Date().toISOString().split('T')[0];
    setDate(currentDate);
  }, []);

  const fetchStudents = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${baseurl}/api/attendance/fetch`, {
        course,
        semester,
        division,
        lab,
      });

      const sortedStudents = response.data.sort((a, b) => a.RollNo.localeCompare(b.RollNo, undefined, { numeric: true }));
      setLoading(false);
      return sortedStudents;
    } catch (error) {
      setLoading(false);
      console.error('Error fetching students:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const checkResponse = await axios.post(`${baseurl}/api/attendance/check`, {
        date,
        hours: selectedHours, // Send the selected hours as an array
        teachername,
        subject,
        course,
        division,
        lab,
      });

      if (checkResponse.data.isMarked) {
        setAlreadyMarked(true);
        alert(checkResponse.data.message);
        setExistingAttendance([]);
        setStudents([]);
      } else {
        const fetchedStudents = await fetchStudents();
        if (fetchedStudents.length === 0) {
          setNoStudentsMessage(true);
        } else {
          setNoStudentsMessage(false);
        }
        setStudents(fetchedStudents);
        setAlreadyMarked(false);
        setExistingAttendance([]);
      }
    } catch (error) {
      console.error('Error checking attendance or fetching students:', error);
    } finally {
      setLoading(false);
    }
  };

  const submitAttendance = async () => {
    setLoading(true);
    try {
      await Promise.all(selectedHours.map(async (hour) => {
        const updatedStudents = students.map((student) => ({
          studentId: student._id,
          date,
          subject,
          hour,
          teachername,
          attendance: student.attendance,
          division,
          lab,
        }));

        await Promise.all(updatedStudents.map(student =>
          axios.post(`${baseurl}/api/attendance`, student)
        ));
      }));

      alert('Attendance updated successfully!');
      setCourse('');
      setSemester('');
      setSubject('');
      setSelectedHours([]); // Reset selected hours
      setDivision('');
      setLab('');
      setStudents([]);
    } catch (error) {
      console.error('Error marking attendance:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAttendanceChange = (studentId, isPresent) => {
    setStudents(prevStudents =>
      prevStudents.map((student) =>
        student._id === studentId ? { ...student, attendance: isPresent ? 'Present' : 'Absent' } : student
      )
    );
  };

  const handleMarkAllPresentChange = (e) => {
    const isChecked = e.target.checked;
    setMarkAllPresent(isChecked);
    setStudents(prevStudents => prevStudents.map((student) => ({
      ...student,
      attendance: isChecked ? 'Present' : 'Absent',
    })));
  };

  const shouldShowLabField = () => {
    const lowerCaseSubject = subject.toLowerCase();
    return lowerCaseSubject.includes('lab') || lowerCaseSubject.includes('project') || lowerCaseSubject.includes('seminar');
  };

  return (
    <div>
      <Navbar />
      <div className="attendance-form">
        <form onSubmit={handleSubmit}>
          <label>
            Course:
            <select value={course} onChange={(e) => setCourse(e.target.value)}>
              <option value="">Select Course</option>
              {courses.map((course) => (
                <option key={course} value={course}>{course}</option>
              ))}
            </select>
          </label>
          <label>
            Semester:
            <select value={semester} onChange={(e) => setSemester(e.target.value)}>
              <option value="">Select Semester</option>
              {semesters.map((semester) => (
                <option key={semester} value={semester}>{semester}</option>
              ))}
            </select>
          </label>
          <label>
            Division:
            <select value={division} onChange={(e) => setDivision(e.target.value)}>
              <option value="">Select Division</option>
              <option value="A">A</option>
              <option value="B">B</option>
              <option value="C">C</option>
            </select>
          </label>
          <label>
            Subject:
            <select value={subject} onChange={(e) => setSubject(e.target.value)}>
              <option value="">Select Subject</option>
              {subjects.map((subject) => (
                <option key={subject} value={subject}>{subject}</option>
              ))}
            </select>
          </label>
          {shouldShowLabField() && (
            <label>
              Lab:
              <select value={lab} onChange={(e) => setLab(e.target.value)}>
                <option value="">Select Lab</option>
                <option value="Lab 1">Lab 1</option>
                <option value="Lab 2">Lab 2</option>
              </select>
            </label>
          )}
          <label>
            Hour(s):
            <select multiple value={selectedHours} onChange={(e) => {
              const options = e.target.options;
              const values = [];
              for (let i = 0; i < options.length; i++) {
                if (options[i].selected) {
                  values.push(options[i].value);
                }
              }
              setSelectedHours(values);
            }}>
              {[1, 2, 3, 4, 5, 6].map((hr) => (
                <option key={hr} value={hr}>{`${hr} hour`}</option>
              ))}
            </select>
          </label>
          <label>
            Date:
            <input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
          </label>
          <button type="submit">Fetch Students</button>
        </form>

        {loading && <p>Loading...</p>}
        {noStudentsMessage && <p>No students found for the selected combination.</p>}

        {alreadyMarked && existingAttendance.length > 0 && (
          <div>
            <p>Attendance is already marked.</p>
            <table className='att'>
              <thead>
                <tr>
                  <th>Roll No</th>
                  <th>Student Name</th>
                  <th>Present</th>
                </tr>
              </thead>
              <tbody>
                {students.map((student) => (
                  <tr key={student._id}>
                    <td>{student.RollNo}</td>
                    <td>{student.name}</td>
                    <td>{student.attendance === 'Present' ? 'Present' : 'Absent'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {!alreadyMarked && students.length > 0 && (
          <div>
            <h2>Mark Attendance for {subject}</h2>
            <label>
              <input
                type="checkbox"
                checked={markAllPresent}
                onChange={handleMarkAllPresentChange}
              />
              Mark all as Present
            </label>
            <table className='att'>
              <thead>
                <tr>
                  <th>Roll No</th>
                  <th>Student Name</th>
                  <th>Present</th>
                </tr>
              </thead>
              <tbody>
                {students.map((student) => (
                  <tr key={student._id}>
                    <td>{student.RollNo}</td>
                    <td>{student.name}</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={student.attendance === 'Present'}
                        onChange={(e) => handleAttendanceChange(student._id, e.target.checked)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button onClick={submitAttendance}>Submit Attendance</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AttendanceForm;
